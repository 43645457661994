<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="车牌号码" type="select" :options="carOption" :initialContent="getCarName(detail.pid)" :disabled="disabled" name="pid" :contentChanged="contentChanged"></inputItem>
        <inputItem title="经办人员" v-model="detail.handler" :disabled="true"></inputItem>
        <inputItem title="项目名称" type="others" ref="project_name" :initialContent="detail.project_name" :otherClicked="clickProject" :disabled="disabled"></inputItem>
        <inputItem title="消费时间" type="datetime" name="pay_time" :initialContent="formatDate(detail.pay_time,'yyyy-MM-dd hh:mm')" :disabled="disabled" :contentChanged="contentChanged"></inputItem>
        <inputItem title="消费类型" type="select" :options="typeOption" :initialContent="getTypeName(detail.type)" :disabled="disabled" name="type" :contentChanged="contentChanged"></inputItem>
        <inputItem title="消费金额" :initialContent="String(detail.price)" :disabled="disabled" name="price" :contentChanged="contentChanged"></inputItem>
        <inputItem title="车辆里程" :initialContent="String(detail.car_mileage)" :disabled="disabled" name="car_mileage" :contentChanged="contentChanged"></inputItem>
        <inputItem title="消费备注" v-model="detail.remark" :disabled="disabled"></inputItem>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
    <optionDrawer ref="optionDrawerProject" v-if="showOptionsProject" :onBgClicked="onBgClickedProject" :onSearch="onSearchProject" :items="projects" @optionClicked="optionClickedProject"> </optionDrawer>
  </div>
</template>

<script>
import { GetCar, GetCarCost, SaveCarCost } from "@/api";
import { formatDate, formatMoney } from "@/utils/format.js";
import { loadListAction, loadDetailAction, submitAction, loadProjectOptionAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      disabled: false,
      carOption: [],
      typeOption: [{ text: '加油费', value: 1 }, { text: '年检费', value: 3 }, { text: '保养费', value: 4 }, { text: '维修费', value: 5 }],
      projects: [],
      showOptionsProject: false,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    getTypeName: function (type) {
      switch (type) {
        case 1:
          return "加油费";
        case 2:
          return "保险费";
        case 3:
          return "年检费";
        case 4:
          return "保养费";
        case 5:
          return "维修费";
      }
    },
    onSearchProject: function (value) {
      loadProjectOptionAction(this, value, (data) => { this.projects = data; });
    },
    onBgClickedProject: function () {
      this.showOptionsProject = false;
    },
    optionClickedProject: function (item) {
      this.detail.project_id = item.id;
      this.detail.project_name = item.name;
      this.$refs.project_name.setContent(item.name);
      this.showOptionsProject = false;
    },
    clickProject: function (index) {
      this.itemIndex = Number(index);
      this.showOptionsProject = true;
      this.$nextTick(() => { setTimeout(() => { this.$refs.optionDrawerProject.show(); }, 100); });
    },
    getCarName: function (pid) {
      var cars = this.carOption.filter(item => { return item.value == pid });
      if (cars.length > 0) {
        return cars[0].text;
      }
      return "";
    },
    contentChanged: function (index, value) {
      switch (index) {
        case "pid":
          this.detail.pid = value;
          break;
        case "type":
          this.detail.type = value;
          break;
        case "price":
          this.detail.price = value;
          break;
        case "car_mileage":
          this.detail.car_mileage = value;
          break;
        case "pay_time":
          this.detail.pay_time = value;
          break;
      }
    },
    buttonClick: function (title) {
      this.saveCarCost(title);
    },
    saveCarCost: function () {
      submitAction(this, SaveCarCost, { model: this.detail }, () => { this.$router.go(-1); }, null, false);
    },
    dataRequest: function () {
      loadDetailAction(this, GetCarCost, { id: this.id }, (data) => {
        this.detail = data.list[0];
        this.buttons = ["提交保存"];
      });
    },
  },
  mounted() {
    loadListAction(this, GetCar, { state: 1, page: { pageIndex: 1, pageSize: 100, } }, (data) => {
      var that = this;
      data.list.forEach(function (item) {
        that.carOption.push({ text: item.car_id + '（' + item.brand + String(item.seat_num) + '座）', value: item.id });
      });

      if (this.$route.query.id) {
        this.id = this.$route.query.id;
        this.dataRequest();
      }
    });
  },
};
</script>
